//@ts-nocheck

import { Box } from '@mui/material';
import { Text, Title, Tooltip } from 'shared/ui';
import { AdditionalEntry, StopLoss, TakeProfit } from '../../buy/components';
import { Fields } from '../../fields';
import { InfoIcon, Wallet } from '../icons';
import { ISell } from '../interfaces';
import { inner, price, priceWrapper, title, tooltipText, wrapper } from '../styles';

export const Sell = (props: ISell) => {
  const {
    isSkipBaseOrder,
  } = props;

  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Title styles={title}>
          {isSkipBaseOrder ? 'Sold MATIC' : 'Sell MATIC'}
        </Title>

        <Box sx={priceWrapper}>
          <Box
            width={12}
            height={12}
          >
            {Wallet}
          </Box>

          <Text
            type='success'
            styles={price}
          >
            77069.244 USDT
          </Text>

          <Tooltip
            title={(
              <Box maxWidth={188}>
                <Title
                  level={5}
                  styles={tooltipText}
                >
                  USDT amount currently <br /> available on Exchange account
                </Title>
              </Box>
            )}
          >
            <Box
              width={12}
              height={12}
            >
              {InfoIcon}
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <Fields
        isSkipBaseOrder={isSkipBaseOrder}
        inputs={!isSkipBaseOrder ? [{
          label: 'Order price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Total',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Units',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }] : [{
          label: 'Sold price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Units',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Total',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }]}
        sliderInput={{
          label: '',
          value: '50',
          onChange: () => {},
          type: 'input',
        }}
      />

      <AdditionalEntry
        inputs={[{
          label: 'Trigger price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Order price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Total',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Units',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }]}
        sliderInput={{
          label: '',
          value: '100',
          onChange: () => {},
          type: 'input',
        }}
      />

      <TakeProfit
        inputs={[{
          label: 'Order price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Trigger price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }]}
      />

      <StopLoss
        inputs={[{
          label: 'Trigger price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }, {
          label: 'Order price',
          value: '',
          onChange: () => {},
          type: 'input',
          suffixText: 'USDT',
        }]}
      />
    </Box>
  );
};

export default Sell;
