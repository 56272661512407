import { SxProps, Theme } from '@mui/material';

export const topWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'initial',
    lg: 'flex-start',
  },
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  backgroundColor: '#F8FBF9',
  flex: 1,
  padding: '46px 0 0',
  '&>div': {
    padding: '16px 22px',
    '&:first-of-type': {
      borderTop: '1px solid #E1E1E1',
      borderRight: '1px solid #E1E1E1',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      position: 'relative',

      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '16px',
        height: '16px',
        borderBottomLeftRadius: '50%',
        boxShadow: '-8px 8px 0 #fff',
        borderBottom: '1px solid #E1E1E1',
        borderLeft: '1px solid #E1E1E1',
        right: '-16px',
        bottom: '-1px',
      },
      backgroundColor: '#fff',
    },
    '&:not(:first-of-type)': {
      borderBottom: '1px solid #E1E1E1',
    },
  },
};
