import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
// import { normalizeExchangeName } from 'src/utils/exchangeUtils';
import { getCurrentSymbol } from '../../../../entities/terminal/model/selectors/get-current-symbol/get-current-symbol';
import { terminalActions } from '../../../../entities/terminal/model/slices/terminal-slice';
import { widget } from '../charting_library';
import { getBars } from '../helpers/get-bars';
import { supportedResolutions } from './constants';
import { resolutionToExchangeInterval } from './helpers';

export const normalizeExchangeName = (exchangeName) => {
  if (exchangeName === 'gateio') {
    return 'gate';
  }
  return exchangeName;
};

export const TVChartContainer = ({
  exchangeName, 
  tradingPair,
  market,
}) => {
  
  const dispatch = useDispatch();
  
  const currentSymbol = useSelector(getCurrentSymbol);
  
  const chartContainerRef = useRef();
  const [symbol, setSymbol] = useState('BTCUSDT');

  const wsRef = useRef(null);

  const unsubscribeFromCurrentStream = () => {
    if (wsRef.current) {
      wsRef.current.close();
      console.log('WebSocket connection closed for previous stream');
      wsRef.current = null;
    }
  };

  useEffect(() => {
    if (currentSymbol) {
      setSymbol(currentSymbol.symbol || 'BTCUSDT');
    }
  }, [currentSymbol, exchangeName, tradingPair, market]);

  const customDatafeed = {
    onReady: (callback) => {
      setTimeout(
        () =>
          callback({
            exchanges: ['binance', 'bybit', 'okx', 'gate', 'crypto-com'],
            symbols_types: [],
            supported_resolutions: supportedResolutions,
            supports_marks: false,
            supports_search: false,
            supports_time: true,
            default_resolution: resolutionToExchangeInterval('1D'),
          }),
        0,
      );
    },
    searchSymbols: async (
      userInput,
      exchange,
      symbolType,
      onResultReadyCallback,
    ) => {
      try {
      } catch (error) {
        console.error('Error searching symbols:', error);
      }
    },
    resolveSymbol: async (symbolName, onResolve, onError) => {
      try {
        let symbolInfo = {
          name: symbolName,
          ticker: symbolName,
          minmov: 1,
          pricescale: 10 ** currentSymbol?.chartPrecision,
          session: '24x7',
          timezone: 'UTC',
          has_intraday: true,
          intraday_multipliers: supportedResolutions,
          visible_plots_set: 'ohlcv',
          has_daily: true,
          has_weekly_and_monthly: true,
          type: 'crypto',
          supported_resolutions: supportedResolutions,
        };
        
        onResolve(symbolInfo);
      } catch (error) {
        console.error('Ошибка при разрешении символа:', error);
        onError(error);
      }
    },
    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      HistoryCallback,
      onErrorCallback,
    ) => {
      try {
        const defaltDataForFetch = {
          symbol: 'BTCUSDT',
          fromDate: periodParams.from * 1000,
          toDate: periodParams.to * 1000,
          chartInterval: resolutionToExchangeInterval(60, 'binance'),
          limit: periodParams.countBack,
          exchange: 'binance',
          instId: exchangeName === 'okx' ? currentSymbol.okxInstId : undefined,
        };
        
        const dataForFetch = {
          symbol: symbolInfo.ticker,
          fromDate: periodParams.from * 1000,
          toDate: periodParams.to * 1000,
          chartInterval: resolutionToExchangeInterval(resolution, exchangeName),
          limit: periodParams.countBack,
          exchange: normalizeExchangeName(exchangeName),
          instId: normalizeExchangeName(exchangeName) === 'okx' ? currentSymbol.okxInstId : undefined,
          gateSymbol: normalizeExchangeName(exchangeName) === 'gate' ? currentSymbol.gateSymbol : undefined,
          cryptoComSymbol: normalizeExchangeName(exchangeName) === 'crypto-com' ? currentSymbol.cryptoComSymbol : undefined,
        };
        
        let bars = [];
        try {
          //TODO: rewrite to send request from client side for bars
          bars = await getBars(exchangeName ? dataForFetch : defaltDataForFetch);
        } catch (error) {
          console.log(error);
        }

        const lastPrice = bars[bars.length - 1]?.close;

        if (periodParams.firstDataRequest) {
          dispatch(terminalActions.setChartLastPrice(lastPrice));
          dispatch(terminalActions.setBaseOrderPrice(lastPrice));
          // dispatch(set_last_price(lastPrice));
        }

        const noData = bars.length > 0 ? false : true;

        HistoryCallback(bars, {
          noData, 
        });
      } catch (error) {
        onErrorCallback(error);
      }
    },
    subscribeBars: (
      symbolInfo,
      resolution,
      onTick,
      subscriberUID,
      onResetCacheNeededCallback,
    ) => {
      unsubscribeFromCurrentStream();

      const normalizedExchangeName = normalizeExchangeName(exchangeName);

      if (normalizedExchangeName === 'binance') {
        const binanceSymbol = symbolInfo.name.toLowerCase();
        const streamName = `${binanceSymbol}@kline_${resolutionToExchangeInterval(
          resolution,
        )}`;

        wsRef.current = new WebSocket(
          `wss://stream.binance.com:9443/ws/${streamName}`,
        );

        wsRef.current.onopen = (event) => {
          console.log('WebSocket Open', event);
        };

        wsRef.current.onmessage = (event) => {
          const message = JSON.parse(event.data);
          const {
            k: kline, 
          } = message;
          if (kline) {
            const bar = {
              time: +kline.t,
              low: +kline.l,
              high: +kline.h,
              open: +kline.o,
              close: +kline.c,
              volume: +kline.v,
            };
            dispatch(terminalActions.setLimitLastPrice(bar.close));
            // dispatch(set_limit_last_price(bar.close));
            onTick(bar);
          }
        };

        wsRef.current.onerror = (error) => {
          console.error('WebSocket Error', error);
        };
      }

      if (normalizedExchangeName === 'bybit') {
        const interval = resolution;
        //should be uppercase for bybit
        const bybitSymbol = symbolInfo.name;

        wsRef.current = new WebSocket('wss://stream.bybit.com/v5/public/spot');

        wsRef.current.onopen = () => {
          const message = {
            op: 'subscribe',
            args: [`kline.${interval}.${bybitSymbol}`],
          };


          wsRef.current.send(JSON.stringify(message));
        };

        wsRef.current.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.topic && message.topic.startsWith('kline')) {
            const kline = message.data;
            if (kline) {
              const bar = {
                time: kline[0].start,
                low: +kline[0].low,
                high: +kline[0].high,
                open: +kline[0].open,
                close: +kline[0].close,
                volume: +kline[0].volume,
              };
              dispatch(terminalActions.setLimitLastPrice(bar.close));
              // dispatch(set_limit_last_price(bar.close));
              onTick(bar);
            }
          }
        };

        wsRef.current.onerror = (error) => {
          console.error('WebSocket Error', error);
        };
      }

      if (normalizedExchangeName === 'okx') {
        const interval = resolutionToExchangeInterval(resolution, 'okx');

        const okxSymbol = currentSymbol?.okxInstId;

        wsRef.current = new WebSocket('wss://ws.okx.com:8443/ws/v5/business');

        wsRef.current.onopen = () => {
          const message = {
            op: 'subscribe',
            args: [
              {
                channel: `${interval}`,
                instId: `${okxSymbol}`,
              },
            ],
          };
          wsRef.current.send(JSON.stringify(message));
        };

        wsRef.current.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.data) {
            const kline = message.data[0];
            if (kline) {
              const bar = {
                time: +kline[0],
                open: +kline[1],
                high: +kline[2],
                low: +kline[3],
                close: +kline[4],
                volume: +kline[5],
              };
              dispatch(terminalActions.setLimitLastPrice(bar.close));
              onTick(bar);
            }
          }
        };

        wsRef.current.onerror = (error) => {
          console.error('WebSocket Error', error);
        };
      }

      if (normalizedExchangeName === 'gate') {
        const gateSymbol = currentSymbol?.gateSymbol;
        const gateioIntervalMap = {
          '1': '1m',
          '5': '5m',
          '15': '15m',
          '30': '30m',
          '60': '1h',
          '240': '4h',
          '480': '8h',
          '1D': '1d',
          '7D': '7d',
        };
        const interval = gateioIntervalMap[resolution] || '1h';

        wsRef.current = new WebSocket('wss://api.gateio.ws/ws/v4/');

        wsRef.current.onopen = () => {
          const message = {
            time: Math.floor(Date.now() / 1000),
            channel: 'spot.candlesticks',
            event: 'subscribe',
            payload: [interval, gateSymbol],
          };
          wsRef.current.send(JSON.stringify(message));
        };

        wsRef.current.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.event === 'update' && message.channel === 'spot.candlesticks') {
            const kline = message.result;
            if (kline && kline.t && kline.o && kline.c && kline.h && kline.l && kline.v) {
              const timeInMs = parseInt(kline.t, 10) * 1000;
              
              const bar = {
                time: timeInMs,
                open: parseFloat(kline.o),
                close: parseFloat(kline.c),
                high: parseFloat(kline.h),
                low: parseFloat(kline.l),
                volume: parseFloat(kline.v),
              };
              dispatch(terminalActions.setLimitLastPrice(bar.close));
              onTick(bar);
            } else {
              console.error('Получены неполные данные свечи:', kline);
            }
          }
        };

        wsRef.current.onerror = (error) => {
          console.error('WebSocket Error', error);
        };
      }

      if (normalizedExchangeName === 'crypto-com') {
        const cryptoComSymbol = currentSymbol?.cryptoComSymbol;
        const interval = resolutionToExchangeInterval(resolution, 'crypto-com');

        wsRef.current = new WebSocket('wss://stream.crypto.com/exchange/v1/market');

        wsRef.current.onopen = () => {
          const message = {
            id: 1,
            method: 'subscribe',
            params: {
              channels: [`candlestick.${interval}.${cryptoComSymbol}`],
            },
            nonce: Date.now(),
          };
          wsRef.current.send(JSON.stringify(message));
        };

        wsRef.current.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.method === 'public/heartbeat') {
            // Отправляем ответ на heartbeat
            const response = {
              id: message.id,
              method: 'public/respond-heartbeat',
            };
            wsRef.current.send(JSON.stringify(response));
          } else if (message.result && message.result.data) {
            const kline = message.result.data[0];
            if (kline) {
              const bar = {
                time: kline.t,
                open: parseFloat(kline.o),
                high: parseFloat(kline.h),
                low: parseFloat(kline.l),
                close: parseFloat(kline.c),
                volume: parseFloat(kline.v),
              };
              dispatch(terminalActions.setLimitLastPrice(bar.close));
              onTick(bar);
            }
          }
        };

        wsRef.current.onerror = (error) => {
          console.error('Ошибка WebSocket', error);
        };
      }
    },

    unsubscribeBars: (listenerGuid) => {},
  };

  const chartingLibraryPath = '/charting_library/';
  
  useEffect(() => {
    const widgetOptions = {
      symbol: symbol,
      datafeed: customDatafeed,
      interval: '60',
      container: chartContainerRef.current,
      library_path: chartingLibraryPath,
      locale: 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'header_symbol_search',
      ],
      enabled_features: ['study_templates'],
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      debug: true,
    };

    const tvWidget = new widget(widgetOptions);

    return () => {
      unsubscribeFromCurrentStream();
      tvWidget.remove();
    };
  }, [symbol, currentSymbol, exchangeName]);

  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};
