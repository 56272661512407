import axios from 'axios';
import { sanitizeSymbol } from '../helpers/common-utils';

interface FetchExchangeProps {
    symbol: any; 
    fromDate: any; 
    toDate: any; 
    chartInterval: any; 
    limit: any;
    instId?: any;
    gateSymbol?: any;
    cryptoComSymbol?: any;
}




export async function fetchBinanceBars(props: FetchExchangeProps) {
  const {
    symbol, fromDate, toDate, chartInterval, limit, 
  } = props;
    
  const url = 'https://api.binance.com/api/v3/klines';
  const params = new URLSearchParams({
    symbol: sanitizeSymbol(symbol),
    interval: chartInterval,
    startTime: fromDate,
    endTime: toDate,
    limit: limit,
  });
  
  const response = await axios.get(url, {
    params, 
  });

  //@ts-ignore
  return response.data.map((item) => ({
    time: item[0],
    open: Number(parseFloat(item[1]).toFixed(8)),
    high: Number(parseFloat(item[2]).toFixed(8)),
    low: Number(parseFloat(item[3]).toFixed(8)),
    close: Number(parseFloat(item[4]).toFixed(8)),
    volume: Number(parseFloat(item[5]).toFixed(8)),
  }));
}
  
export async function fetchBybitBars(props: FetchExchangeProps) {
  const {
    symbol, fromDate, toDate, chartInterval, limit, 
  } = props;
    
  const url = 'https://api.bybit.com/v5/market/kline';
  const params = new URLSearchParams({
    symbol: symbol,
    category: 'spot',
    interval: chartInterval,
    start: fromDate,
    end: toDate,
    limit: limit,
  });
  
  //   const response = await fetch(`${url}?${params}`);
  //   const response = await fetch(`${url}?${params}`);
  const response = await axios.get(url, {
    params, 
  });
  
  //   const data = await response.json();
  //@ts-ignore
  return response.data.result.list.map((item) => ({
    time: Number(item[0]),
    open: Number(parseFloat(item[1]).toFixed(8)),
    high: Number(parseFloat(item[2]).toFixed(8)),
    low: Number(parseFloat(item[3]).toFixed(8)),
    close: Number(parseFloat(item[4]).toFixed(8)),
    volume: Number(parseFloat(item[5]).toFixed(8)),
  })).reverse();
}
  
export async function fetchOkxBars(props: FetchExchangeProps) {
  const {
    // symbol, 
    // fromDate, 
    toDate, 
    // chartInterval, 
    limit, 
    instId, 
  } = props;
    
  const url = 'https://www.okx.com/api/v5/market/candles';
  const params = new URLSearchParams({
    instId: instId ?? 'BTC-USDT',
    bar: '1H',
    after: toDate,
    limit: limit,
  });
  
  const response = await fetch(`${url}?${params}`);
  const data = await response.json();
  //@ts-ignore
  return data.data.map((item) => ({
    time: Number(item[0]),
    open: Number(parseFloat(item[1]).toFixed(8)),
    high: Number(parseFloat(item[2]).toFixed(8)),
    low: Number(parseFloat(item[3]).toFixed(8)),
    close: Number(parseFloat(item[4]).toFixed(8)),
    volume: Number(parseFloat(item[5]).toFixed(8)),
  })).reverse();
}
  
export async function fetchGateBars(props: FetchExchangeProps) {
  const {
    fromDate,
    toDate,
    chartInterval,
    limit,
    gateSymbol,
  } = props;
  
  try {
    const apiUrl = 'https://api.gateio.ws/api/v4/spot/candlesticks';
    
    const params = new URLSearchParams({
      currency_pair: gateSymbol,
      from: Math.floor(fromDate / 1000).toString(),
      to: Math.floor(toDate / 1000).toString(),
      interval: chartInterval,
      limit: limit.toString(),
    });

    const response = await axios.get(apiUrl, {
      params, 
    });

    return response.data.map((item: any) => ({
      time: Number(item[0]) * 1000,
      open: Number(parseFloat(item[5]).toFixed(8)),
      high: Number(parseFloat(item[3]).toFixed(8)),
      low: Number(parseFloat(item[4]).toFixed(8)),
      close: Number(parseFloat(item[2]).toFixed(8)),
      volume: Number(parseFloat(item[1]).toFixed(8)),
    }));
  } catch (error) {
    console.error('Ошибка при получении данных из Gate.io:', error);
    throw error;
  }
}

export async function fetchCryptocomBars(props: FetchExchangeProps) {
  const {
    fromDate,
    toDate,
    chartInterval,
    limit,
    cryptoComSymbol,
  } = props;

  try {
    const apiUrl = 'https://api.crypto.com/exchange/v1/public/get-candlestick';
    
    const params = new URLSearchParams({
      instrument_name: cryptoComSymbol,
      timeframe: chartInterval,
      count: limit,
      start_ts: fromDate.toString(),
      end_ts: toDate.toString(),
    });

    const response = await axios.get(apiUrl, {
      params, 
    });

    if (response.data.result && response.data.result.data) {
      return response.data.result.data.slice(0, limit).map((item: any) => ({
        time: Number(item.t),
        open: Number(parseFloat(item.o).toFixed(8)),
        high: Number(parseFloat(item.h).toFixed(8)),
        low: Number(parseFloat(item.l).toFixed(8)),
        close: Number(parseFloat(item.c).toFixed(8)),
        volume: Number(parseFloat(item.v).toFixed(8)),
      }));
    } else {
      throw new Error('Неверный формат ответа от API Crypto.com');
    }
  } catch (error) {
    console.error('Ошибка при получении данных из Crypto.com:', error);
    throw error;
  }
}
