export const calculateUnits = (total: number, price: number, precision: number) => {
  return (total / price).toFixed(precision);
};
  
export const calculateTotal = (units: number, price: number, precision: number) => {
  return (units * price).toFixed(precision);
};

export function formatByPrecisionAndTrim(
  value: string,
  precision: number,
  min: number,
  max: number,
): string {
  let formattedValue = value.replace(/[^\d.]/g, '');
  const parts = formattedValue.split('.');
  formattedValue = parts[0] + (parts.length > 1 ? '.' + parts.slice(1).join('') : '');
  formattedValue = formattedValue.replace(/\.$/, '');
  
  if (formattedValue !== '') {
    const numValue = parseFloat(formattedValue);
    if (!isNaN(numValue)) {
      formattedValue = Math.max(min, Math.min(max, numValue)).toFixed(precision);
    }
  }
  
  if (formattedValue !== '') {
    const numValue = parseFloat(formattedValue);
    if (!isNaN(numValue)) {
      formattedValue = Math.max(min, Math.min(max, numValue)).toFixed(precision);
      formattedValue = formattedValue.replace(/\.?0+$/, '');
    }
  }
  
  return formattedValue;
}

export function getTrailedZeroCutted(num: number | string): string {
  return Number(num).toString();
}

export function roundPercentage(value: number): number {
  return Math.round(value);
}
