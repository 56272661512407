//@ts-nocheck

import { Box } from '@mui/material';
import { Button } from 'antd';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { getCurrentSymbol } from 'entities/terminal/model/selectors/get-current-symbol/get-current-symbol';
import { getLimitLastPrice } from 'entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { getUserWalletSelectedSymbol } from 'entities/terminal/model/selectors/get-userwallet-selected-symbol/get-userwallet-selected-symbol';
import { getWalletData } from 'entities/terminal/model/selectors/get-wallet-data/get-wallet-data';
import { collectFormData } from 'pages/manual-trading/trading-terminal/helpers/collect-form-data';
import { calculateTotal, calculateUnits, formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ConfirmTransaction, Text, Title, Tooltip } from 'shared/ui';
import { Fields } from '../../fields';
import { AdditionalEntry, StopLoss, TakeProfit } from '../components';
import { InfoIcon, Wallet } from '../icons';
import { IBuy } from '../interfaces';
import { inner, price, priceWrapper, title, tooltipText, wrapper } from '../styles';

interface Entry {
  orderType: string;
  price: number;
  total: number;
}

export const Buy = (props: IBuy) => {
  const {
    isSkipBaseOrder,
    currentSymbol,
    userWallet,
    userWalletBaseAsset,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmationData, setConfirmationData] = useState([]);
  
  const [lastChangedField, setLastChangedField] = useState<string | null>(null);
 
  const setIsSkipAdditionalEntry = (value: boolean) => {
    setValue('addEntryEnabled', value);
  };
  
  const setIsSkipTakeProfit = (value: boolean) => {
    setValue('takeProfitEnabled', value);
  };
  
  const setIsSkipStopLoss = (value: boolean) => {
    setValue('stopLossEnabled', value);
  };
  
  const chartLastPrice = useSelector(getChartLastPrice);
  const limitLastPrice = useSelector(getLimitLastPrice);
  
  // const currentSymbol = useSelector(getCurrentSymbol);
  
  // const currentSymbol = {
  //   symbol: 'SOLUSDT',
  //   baseAsset: 'SOL',
  //   quoteAsset: 'USDT',
    
  //   quoteAssetPrecision: 2, // usdt - конвертированный до разряда priceTickSize
  //   priceTickSize: '0.01000000', // округление до разряда (quoteAssetPrecision)
  //   priceMin: '0.01000000', // минимальная цена (минимальное значение orderPrice)
  //   priceMax: '1000000.00000000', // максимальное значение цены
  //   minNotional: '5.00000000', // минимальный размер total для любого ордера
  //   maxNotional: '9000000.00000000', // максимальный размер total для любого ордера
  //   lotMax: '90000.00000000', // максимальный размер units для limit order 
  //   lotMin: '0.00100000', // минимальный размер units для limit order 
  //   baseAssetPrecision: 3, // SOL Units - конвертированный до разряда lotTickSize
  //   lotTickSize: '0.00100000', // округление до разряда ( baseAssetPrecision)
  //   marketLotMax: '60781.99265416', // максимальный размер units для market order
  //   marketLotMin: '0.00000000', // минимальный размер units для market order
  //   chartPrecision: 2, // цена на графике (usdt)
  //   baseCurrencyChartPrecision: 3, // то же, что и baseAssetPrecision
  //   limitLastPrice: 60061.99,
  //   chartLastPrice: 60146,
  //   baseOrderPrice: 60146,
  // };
  
  // @TODO add type annotations for form values
  const methods = useForm({
    defaultValues: {
      currentSymbol: currentSymbol,
      baseAsset: currentSymbol?.baseAsset,
      quoteAsset: currentSymbol?.quoteAsset,
      orderPrice: chartLastPrice.toString(),
      orderType: 'limit',
      conditionalOrderType: 'limit',
      units: '',
      total: '',
      slider: 10,
      boughtPrice: '',
      triggerPrice: '',
      triggerPriceType: 'last',
      additionalOrderPrice: (chartLastPrice * 0.95).toString(),
      additionalOrderPricePercent: '',
      additionalTriggerPricePercent: '',
      additionalSlider: 10,
      additionalUnits: '',
      additionalTotal: '',
      additionalOrderType: 'limit',
      additionalTriggerPriceType: 'last',
      additionalEntries: [],
      takeProfitEntries: [],
      takeProfitOrderPrice: (chartLastPrice * 1.1).toString(),
      takeProfitTriggerPrice: (chartLastPrice * 1.1).toString(),
      takeProfitOrderType: 'limit',
      takeProfitOrderPricePercent: '',  
      takeProfitTriggerPricePercent: '',
      takeProfitTriggerPriceType: 'last',
      takeProfitSlider: 100,
      stopLossTriggerPrice: (chartLastPrice * 0.9).toString(),
      stopLossOrderPrice: (chartLastPrice * 0.9).toString(),
      stopLossOrderPricePercent: '',
      stopLossTriggerPricePercent: '',
      stopLossOrderType: 'cond.limit',
      stopLossTriggerPriceType: 'last',
      conditionalSegment: '',
      additionalTriggerPrice: '',
      addEntryEnabled: false,
      takeProfitEnabled: false,
      stopLossEnabled: false,
    },
  });

  const onSubmit = (data: any) => {
    const exchangeUuid = '123';
    
    const collectedData = collectFormData(data, isSkipBaseOrder, exchangeUuid);
    debugger;
    const formattedData = formatDataForConfirmation(collectedData);
    //@ts-ignore
    setConfirmationData(formattedData);
    setIsModalVisible(true);
  };

  
  const formatDataForConfirmation = (data: any) => {
    const result = [];

    // Base order
    result.push({
      title: 'Base order',
      columns: ['Side', 'Price', 'Units', 'Total', 'Type'],
      items: [[
        {
          value: data.baseOrder.side, 
        },
        {
          value: data.baseOrder.limitPrice || data.baseOrder.triggerPrice || 'Market', 
        },
        {
          value: `${data.baseOrder.unitsBase} ${data.baseSymbol}`, 
        },
        {
          value: `${(data.baseOrder.limitPrice * data.baseOrder.unitsBase).toFixed(2)} ${data.quoteSymbol}`, 
        },
        {
          value: data.baseOrder.type, 
        },
      ]],
    });

    // Additional base orders
    if (data.additionalBaseOrders && data.additionalBaseOrders.length > 0) {
      result.push({
        title: 'Additional base orders',
        columns: ['Side', 'Price', 'Units', 'Total', 'Type'],
        items: data.additionalBaseOrders.map(order => [
          {
            value: order.side, 
          },
          {
            value: order.limitPrice || order.triggerPrice || 'Market', 
          },
          {
            value: `${order.unitsBase} ${data.baseSymbol}`, 
          },
          {
            value: `${(order.limitPrice * order.unitsBase).toFixed(2)} ${data.quoteSymbol}`, 
          },
          {
            value: order.type, 
          },
        ]),
      });
    }
  
    // Take profit targets
    if (data.takeProfits && data.takeProfits.length > 0) {
      result.push({
        title: 'Take profit targets',
        columns: ['Volume', 'Price', 'Percent', 'Calculation', 'Type'],
        items: data.takeProfits.map(tp => [
          {
            value: `${tp.percent}%`, 
          },
          {
            value: tp.limitPrice || tp.triggerPrice, 
          },
          {
            value: `${((tp.limitPrice || tp.triggerPrice) / data.baseOrder.limitPrice - 1) * 100}%`, type: 'success', 
          },
          {
            value: tp.priceRecalculation, 
          },
          {
            value: tp.type, 
          },
        ]),
      });
    }

    // Stop loss
    if (data.stopLoss) {
      result.push({
        title: 'Stop loss',
        columns: ['Percent', 'Price', 'Calculation', 'Type'],
        items: [[
          {
            value: `${((data.stopLoss.triggerPrice / data.baseOrder.limitPrice - 1) * 100).toFixed(2)}%`, type: 'danger', 
          },
          {
            value: data.stopLoss.triggerPrice, 
          },
          {
            value: data.stopLoss.priceRecalculation, 
          },
          {
            value: data.stopLoss.type, 
          },
        ]],
      });
    }

    return result;
  };
  
  
  
  const {
    setValue, watch, getValues,
  } = methods;
  
  useEffect(() => {
    setValue('currentSymbol', currentSymbol);
    setValue('baseAsset', currentSymbol.baseAsset);
    setValue('quoteAsset', currentSymbol.quoteAsset);
  }, [currentSymbol?.baseAsset, currentSymbol?.quoteAsset, setValue]);
  
  
  const isAddEntryEnabled = watch('addEntryEnabled');
  const isTakeProfitEnabled = watch('takeProfitEnabled');
  const isStopLossEnabled = watch('stopLossEnabled');
  
  const orderType = watch('orderType');
  const orderPrice = watch('orderPrice');
  const additionalOrderType = watch('additionalOrderType');
  const conditionalOrderType = watch('conditionalOrderType');
  const units = watch('units');
  const total = watch('total');
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  const stopLossOrderPrice = watch('stopLossOrderPrice');
  const takeProfitTriggerPrice = watch('takeProfitTriggerPrice');
  const takeProfitOrderPrice = watch('takeProfitOrderPrice');
  const stopLossTriggerPrice = watch('stopLossTriggerPrice');
  const takeProfitOrderType = watch('takeProfitOrderType');
  
  /// INITIAL VALUES SET START
  
  const setInitialValues = () => {
    const initialOrderPrice = orderType === 'market' ? limitLastPrice : chartLastPrice;
    setValue('orderPrice', formatByPrecisionAndTrim(initialOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)), {
      shouldValidate: true,
    });
    
    if (orderType === 'conditional') {
      setValue('triggerPrice', formatByPrecisionAndTrim(initialOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
    }
    
    if (isSkipBaseOrder) {
      setValue('slider', 100);
      setValue('skipBaseUnits', formatByPrecisionAndTrim(userWallet.free.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax)));
      const initialTotal = calculateTotal(userWallet.free, initialOrderPrice, currentSymbol.quoteAssetPrecision);
      setValue('skipBaseTotal', formatByPrecisionAndTrim(initialTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional)));
    } else {
      const initialTotal = userWallet.free * 0.1;
      setValue('total', formatByPrecisionAndTrim(initialTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional)));
      const initialUnits = calculateUnits(initialTotal, initialOrderPrice, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(initialUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('units', formattedUnits);
      setValue('slider', 10);
    }
  
    setLastChangedField(null);
  };
  
  // Устанавливаем начальные значения при загрузке омпонента
  useEffect(() => {
    setInitialValues();
  }, [orderType, chartLastPrice, isSkipBaseOrder]);
  
  useEffect(() => {
    if (orderType === 'market' && !isSkipBaseOrder) {
      setValue('orderPrice', formatByPrecisionAndTrim(limitLastPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
      
      if (!isNaN(+units) && lastChangedField !== 'units') {
        const newUnits = +total / limitLastPrice;
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
      }
      
      if (!isNaN(+total) && lastChangedField !== null && lastChangedField !== 'total') {
        const newTotal = +units * limitLastPrice;
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
      }
    }
  }, [orderType, limitLastPrice]);
  
  //default values for !market order base order
  useEffect(() => {
    if (isSkipBaseOrder) {
      setValue('boughtPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
    }
    
    if (orderType !== 'market') {
      const newOrderPrice = chartLastPrice;
      setValue('orderPrice', formatByPrecisionAndTrim(newOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
        
      if (orderType === 'conditional') {
        setValue('triggerPrice', formatByPrecisionAndTrim(newOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax)));
      }
        
      if (!isNaN(+units) && lastChangedField !== 'units') {
        const newUnits = +total / newOrderPrice;
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
      }
    }
  }, [orderType, chartLastPrice, isSkipBaseOrder]);
  
    
  const calculatePercentDifference = useCallback((additionalPrice: number, basePrice: number) => {
    const percentDifference = ((additionalPrice - basePrice) / basePrice) * 100;
    return roundPercentage(percentDifference);
  }, []);
  
  const updateAdditionalEntryValues = useCallback(() => {
    if (isAddEntryEnabled) {
      return;
    }

    const currentOrderPrice = parseFloat(orderPrice);
    const currentAdditionalOrderPrice = currentOrderPrice * 0.95;

    const formattedAdditionalOrderPrice = formatByPrecisionAndTrim(
      currentAdditionalOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );

    setValue('additionalOrderPrice', formattedAdditionalOrderPrice);
    setValue('additionalTriggerPrice', formattedAdditionalOrderPrice);
  
    const additionalTotalCalculated = userWallet.free * 0.1;
    const formattedAdditionalTotal = formatByPrecisionAndTrim(
      additionalTotalCalculated.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
  
    const additionalUnitsCalculated = calculateUnits(additionalTotalCalculated, currentAdditionalOrderPrice, currentSymbol.baseAssetPrecision);
    const formattedAdditionalUnits = formatByPrecisionAndTrim(
      additionalUnitsCalculated.toString(),
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    );
  
    setValue('additionalTotal', formattedAdditionalTotal);
    setValue('additionalUnits', formattedAdditionalUnits);

    const percentDifference = calculatePercentDifference(currentAdditionalOrderPrice, currentOrderPrice);
    const roundedPercentDifference = Math.round(percentDifference);
  
    setValue('additionalOrderPricePercent', roundedPercentDifference.toString());
    setValue('additionalTriggerPricePercent', roundedPercentDifference.toString());
    setValue('additionalSlider', 10);
  
  }, [
    isAddEntryEnabled, 
    orderPrice,
    setValue, 
    calculatePercentDifference, 
    userWallet.free, 
    currentSymbol?.baseAssetPrecision, 
    currentSymbol?.quoteAssetPrecision,
  ]);

  useEffect(() => {
    if (!isAddEntryEnabled) {
      updateAdditionalEntryValues();
    }
  }, [isAddEntryEnabled, orderPrice, updateAdditionalEntryValues]);
  
  
  
  /// TAKE PROFIT CROSS CHANGING VALUES
  const updateTakeProfitValues = useCallback(() => {
    if (isTakeProfitEnabled) {
      return; 
    }
  
    const currentOrderPrice = parseFloat(orderPrice);
    const currentTakeProfitOrderPrice = currentOrderPrice * 1.1;
    const percentDifference = calculatePercentDifference(currentTakeProfitOrderPrice, currentOrderPrice);
  
    const roundedTakeProfitOrderPrice = formatByPrecisionAndTrim(
      currentTakeProfitOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
  
    const roundedPercentDifference = Math.round(percentDifference).toString();
  
    setValue('takeProfitOrderPrice', roundedTakeProfitOrderPrice);
    setValue('takeProfitTriggerPrice', roundedTakeProfitOrderPrice);
    setValue('takeProfitOrderPricePercent', roundedPercentDifference);
    setValue('takeProfitTriggerPricePercent', roundedPercentDifference);

  }, [isTakeProfitEnabled, orderPrice, setValue, calculatePercentDifference, currentSymbol?.quoteAssetPrecision]);

  useEffect(() => {
    if (!isTakeProfitEnabled) {
      updateTakeProfitValues();
    }
  }, [isTakeProfitEnabled, orderPrice, updateTakeProfitValues]);
  
  /// STOP LOSS CROSS CHANGING VALUES

  const updateStopLossValues = useCallback(() => {
    if (isStopLossEnabled) {
      return; 
    }
  
    const currentOrderPrice = parseFloat(orderPrice);
    const currentStopLossOrderPrice = currentOrderPrice * 0.9;
    const percentDifference = calculatePercentDifference(currentStopLossOrderPrice, currentOrderPrice);
  
    const formattedStopLossOrderPrice = formatByPrecisionAndTrim(
      currentStopLossOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
  
    const roundedPercentDifference = Math.round(percentDifference);
  
    setValue('stopLossOrderPrice', formattedStopLossOrderPrice);
    setValue('stopLossTriggerPrice', formattedStopLossOrderPrice);
    setValue('stopLossOrderPricePercent', roundedPercentDifference.toString());
    setValue('stopLossTriggerPricePercent', roundedPercentDifference.toString());

  }, [isStopLossEnabled, orderPrice, setValue, calculatePercentDifference, currentSymbol?.quoteAssetPrecision]);

  useEffect(() => {
    if (!isStopLossEnabled) {
      updateStopLossValues();
    }
  }, [isStopLossEnabled, orderPrice, updateStopLossValues]);
  
  
  /// INITIAL VALUES SET END
  
  
  /// SAFETY ORDERS START
  
  const handleAddEntry = useCallback(() => {
    const {
      additionalOrderType, additionalOrderPrice, additionalTriggerPrice, additionalTotal, additionalEntries,
    } = getValues();
    const price = additionalOrderType === 'cond.market' ? additionalTriggerPrice : additionalOrderPrice;

    const baseTotal = watch('total');
    
    
    const newEntry = {
      additionalOrderType,
      price: parseFloat(price),
      total: parseFloat(additionalTotal),
    } as Entry;
    
    if (additionalEntries.some((entry: { price: number; }) => entry.price === newEntry.price)) {
      console.error('=====Ордер с такой ценой уже существует');
      return;
    }
    
    const totalSum = additionalEntries.reduce((sum, entry) => sum + entry.total, 0) + 
    parseFloat(baseTotal) + 
    newEntry.total;

    if (totalSum > userWallet.free) {
      console.error('====Недостаточно средств для размещения этого ордера');
      return;
    }
    
    const updatedEntries = [...additionalEntries, newEntry] as Entry[];
    setValue('additionalEntries', updatedEntries as any, {
      shouldValidate: true, 
    });
    
  }, [getValues, setValue, additionalOrderType]);

  
  const handleRemoveEntry = useCallback((index: number) => {
    const {
      additionalEntries, 
    } = getValues();
    const updatedEntries = additionalEntries.filter((_, i) => i !== index);
    setValue('additionalEntries', updatedEntries, {
      shouldValidate: true, 
    });
  }, [getValues, setValue]);
  
  /// SAFETY ORDERS END
  
  /// TAKE PROFIT SPLIT TARGET START
  
  const handleAddTakeProfitEntry = useCallback(() => {
    const {
      takeProfitOrderPrice, takeProfitTriggerPrice, takeProfitEntries, takeProfitSlider,
    } = getValues();
    const price = takeProfitOrderType === 'cond.market' ? takeProfitTriggerPrice : takeProfitOrderPrice;
    const volume = takeProfitSlider;
    
    if (volume <= 0) {
      console.error('Объем должен быть больше 0');
      return;
    }
    
    const newEntry = {
      takeProfitOrderType,
      price: parseFloat(price),
      volume: volume,
    };
    

    if (takeProfitEntries.some((entry: { price: number; }) => entry.price === newEntry.price)) {
      // Показать сообщение об ошибке, что ордер с такой ценой уже существует
      console.error('Ордер с такой ценой уже существует');
      return;
    }
    
    const updatedEntries = [...takeProfitEntries, newEntry];
    setValue('takeProfitEntries', updatedEntries as any, {
      shouldValidate: true, 
    });
    
    const totalVolume = updatedEntries.reduce((sum, entry) => sum + entry.volume, 0);
  
    const newSliderValue = Math.max(0, 100 - totalVolume);
    setValue('takeProfitSlider', newSliderValue);
    
  }, [getValues, setValue, takeProfitTriggerPrice, takeProfitOrderPrice, takeProfitOrderType]);

  const handleRemoveTakeProfitEntry = useCallback((index: number) => {
    const {
      takeProfitEntries, 
    } = getValues();
    const updatedEntries = takeProfitEntries.filter((_, i) => i !== index);
    setValue('takeProfitEntries', updatedEntries, {
      shouldValidate: true, 
    });
  }, [getValues, setValue]);
  
  
  /// TAKE PROFIT SPLIT TARGET END
  
  
  
  ///// BASE ORDER METHODS START
  const handleTriggerPriceChange = useCallback((value: string) => {
    if (conditionalOrderType === 'limit') {
      return;
    }
    
    const triggerPrice = parseFloat(value);
    if (!isNaN(triggerPrice) && orderType === 'conditional' && conditionalOrderType === 'market') {
      if (lastChangedField === 'total') {
        const newUnits = calculateUnits(parseFloat(total), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(units), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
        const percentageValue = (parseFloat(newTotal) / userWallet.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
  }, [orderType, conditionalOrderType, lastChangedField, total, units, userWallet.free, calculateUnits, calculateTotal, setValue]);

  const handleOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    
    if (!isNaN(price)) {
      if (lastChangedField === 'total') {
        const newUnits = calculateUnits(parseFloat(total), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(units), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
        
        const percentageValue = (parseFloat(newTotal) / userWallet.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
    
    const additionalOrderPricePercentDifference = calculatePercentDifference(+additionalOrderPrice, price);
    setValue('additionalOrderPricePercent', additionalOrderPricePercentDifference.toString());

    const additionalTriggerPricePercentDifference = calculatePercentDifference(+additionalTriggerPrice, price);
    setValue('additionalTriggerPricePercent', additionalTriggerPricePercentDifference.toString());
    
    const stopLossOrderPricePercentDifference = calculatePercentDifference(+stopLossOrderPrice, price);
    setValue('stopLossOrderPricePercent', stopLossOrderPricePercentDifference.toString());
    
    const stopLossTriggerPricePercentDifference = calculatePercentDifference(+stopLossTriggerPrice, price);
    setValue('stopLossTriggerPricePercent', stopLossTriggerPricePercentDifference.toString());
    
    const takeProfitTriggerPricePercentDifference = calculatePercentDifference(+takeProfitTriggerPrice, price);
    setValue('takeProfitTriggerPricePercent', takeProfitTriggerPricePercentDifference.toString());
    
    const takeProfitOrderPricePercentDifference = calculatePercentDifference(+takeProfitOrderPrice, price);
    setValue('takeProfitOrderPricePercent', takeProfitOrderPricePercentDifference.toString());
  }, [lastChangedField, additionalOrderPrice,total, userWallet.free, units, stopLossTriggerPrice, takeProfitTriggerPrice, additionalTriggerPrice, takeProfitOrderPrice, price, calculateUnits, calculateTotal, setValue]);
  
  const handleBoughtPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'units') {
        const newTotal = calculateTotal(parseFloat(units), price, currentSymbol.quoteAssetPrecision );
        setValue('total', newTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(total), price, currentSymbol.baseAssetPrecision);
        setValue('units', newUnits);
        const percentageValue = (parseFloat(newUnits) / userWallet.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
  }, [units, userWallet.free, calculateTotal, setValue]);
  
  const handleTotalChange = useCallback((value: string) => {
    setLastChangedField('total');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(orderPrice), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('units', formattedUnits);
      
      const percentageValue = (newTotal / userWallet.free) * 100;
      setValue('slider', roundPercentage(percentageValue));
    }
  }, [userWallet, orderPrice, lastChangedField, calculateUnits, setValue]);
  
  const handleSkipBaseTotalChange = useCallback((value: string) => {
    setLastChangedField('total');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(orderPrice), currentSymbol.baseAssetPrecision);
      setValue('units', newUnits);
      
      const percentageValue = (parseFloat(newUnits) / userWallet.free) * 100;
      setValue('slider', roundPercentage(percentageValue));
    }
  }, [orderPrice, lastChangedField, calculateUnits, setValue]);
  
  const handleUnitsChange = useCallback((value: string) => {
    setLastChangedField('units');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(orderPrice), currentSymbol.quoteAssetPrecision);

      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('total', formattedTotal);
      
      const percentageValue = (parseFloat(formattedTotal) / userWallet.free) * 100;
      setValue('slider', roundPercentage(percentageValue));
    }
  }, [orderPrice, userWallet, lastChangedField, calculateTotal, setValue]);
  
  const handleSkipBaseUnitsChange = useCallback((value: string) => {
    setLastChangedField('units');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(orderPrice), currentSymbol.quoteAssetPrecision);
      
      const percentageValue = (parseFloat(newUnits) / userWallet.free) * 100;
      setValue('slider', roundPercentage(percentageValue));
      setValue('total', newTotal);
    }
  }, [orderPrice, lastChangedField, calculateTotal, setValue]);

  const handleSliderChange = useCallback((value: number) => {
    setLastChangedField('total');
    const newTotal = (userWallet.free * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('total', formattedTotal);
    
    const newUnits = calculateUnits(newTotal, parseFloat(orderPrice), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('units', formattedUnits);
  }, [userWallet, lastChangedField, orderPrice, currentSymbol?.quoteAssetPrecision, calculateUnits, setValue]);

  const handleSkipBaseSliderChange = useCallback((value: number) => {
    setLastChangedField('units');
    const newUnits = (userWallet.free * value) / 100;
    const newTotal = calculateTotal(newUnits, parseFloat(orderPrice), currentSymbol.quoteAssetPrecision);
    setValue('units', newUnits.toString());
    setValue('total', parseFloat(newTotal).toFixed(currentSymbol.quoteAssetPrecision));
  }, [userWallet, orderPrice, currentSymbol?.quoteAssetPrecision, calculateUnits, setValue]);
  
  ///// BASE ORDER METHODS END
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  ///// ADDITIONAL ORDER METHODS START
  
  const handleAdditionalTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);
    
    if (!isNaN(triggerPrice)) {
      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('additionalTriggerPricePercent', percentDifference);
      } else {
        setValue('additionalTriggerPricePercent', '0');
      }
    }
    
    if (!isNaN(triggerPrice) && additionalOrderType === 'cond.market') {
      if (lastChangedField === 'additionalTotal') {
        const newUnits = calculateUnits(parseFloat(watch('additionalTotal')), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('additionalUnits', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(watch('additionalUnits')), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('additionalTotal', formattedTotal);
      }
    }
    
  }, [setValue, additionalTriggerPrice, orderPrice, additionalOrderType, calculatePercentDifference, currentSymbol?.quoteAssetPrecision]);
  
  const handleAdditionalOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(price)) {
      if (lastChangedField === 'additionalTotal') {
        const newUnits = calculateUnits(parseFloat(watch('additionalTotal')), price, currentSymbol.baseAssetPrecision);
        setValue('additionalUnits', newUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(watch('additionalUnits')), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('additionalTotal', formattedTotal);
        
        const percentageValue = (parseFloat(formattedTotal) / userWallet.free) * 100;
        setValue('additionalSlider', roundPercentage(percentageValue));
        
      }
    }
    
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('additionalOrderPricePercent', percentDifference.toString());
    }
    
  }, [lastChangedField, watch, setValue, orderPrice]);
  
  const handleAdditionalOrderPricePercentChange = (value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newAdditionalOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedAdditionalOrderPrice = formatByPrecisionAndTrim(newAdditionalOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('additionalOrderPrice', formattedAdditionalOrderPrice);
    }
  };
  
  const handleAdditionalUnitsChange = useCallback((value: string) => {
    setLastChangedField('additionalUnits');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(watch('additionalOrderPrice')), currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('additionalTotal', formattedTotal);
      
      const percentageValue = (parseFloat(formattedTotal) / userWallet.free) * 100;
      setValue('additionalSlider', roundPercentage(percentageValue));
    }
  }, [watch, calculateTotal, setValue]);
  
  const handleAdditionalTotalChange = useCallback((value: string) => {
    setLastChangedField('additionalTotal');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(watch('additionalOrderPrice')), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('additionalUnits', formattedUnits);
    }
  }, [watch, calculateUnits, setValue]);
  
  const handleAdditionalSliderChange = useCallback((value: number) => {
    setLastChangedField('additionalTotal');
    const newTotal = (userWallet.free * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('additionalTotal', formattedTotal);
    
    const newUnits = calculateUnits(newTotal, parseFloat(watch('additionalOrderPrice')), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('additionalUnits', formattedUnits);
  }, [userWallet, currentSymbol?.quoteAssetPrecision, watch, calculateUnits, setValue]);
  
  ///// ADDITIONAL ORDER METHODS END
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  //// TAKE PROFIT METHODS START
  
  const handleTakeProfitTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);
    
    if (!isNaN(triggerPrice)) {
      setValue('takeProfitTriggerPrice', triggerPrice.toString());
      
      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('takeProfitTriggerPricePercent', percentDifference);
      } else {
        setValue('takeProfitTriggerPricePercent', '0');
      }
      
      // Здесь можно добавить дополнительную логику, если необходимо
    }
  }, [setValue, orderPrice, calculatePercentDifference]);
  
  const handleTakeProfitOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('takeProfitOrderPricePercent', percentDifference.toString());
    }
    
  }, [setValue, orderPrice, calculatePercentDifference]);
  
  const handleTakeProfitOrderPricePercentChange = (value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newTakeProfitOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedTakeProfitOrderPrice = formatByPrecisionAndTrim(newTakeProfitOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('takeProfitOrderPrice', formattedTakeProfitOrderPrice);
    }
  };
  
  
  //// TAKE PROFIT METHODS END
  
  
  
  
  
  
  
  
  
  
  
  //// STOP LOSS METHODS START
  
  
  const handleStopLossTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);
    
    if (!isNaN(triggerPrice)) {
      setValue('stopLossTriggerPrice', triggerPrice.toString());
        
      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('stopLossTriggerPricePercent', percentDifference);
      } else {
        setValue('stopLossTriggerPricePercent', '0');
      }
      
      // Здесь можно добавить дополнительную логику, если необходимо
    }
  }, [setValue, orderPrice, calculatePercentDifference]);
  
  const handleStopLossOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('stopLossOrderPricePercent', percentDifference.toString());
    }
    
  }, [setValue, orderPrice, calculatePercentDifference]);
  
  const handleStopLossOrderPricePercentChange = (value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newStopLossOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedStopLossOrderPrice = formatByPrecisionAndTrim(newStopLossOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('stopLossOrderPrice', formattedStopLossOrderPrice);
    }
  };
  
  //// STOP LOSS METHODS END  
  // if (!currentSymbol) return null;
  
  return (
    <>
      <FormProvider {...methods}>
        <Box sx={wrapper} component='form' onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={inner}>
            <Title styles={title}>
              {isSkipBaseOrder ? `Bought ${currentSymbol?.quoteAsset}` : `Buy ${currentSymbol?.quoteAsset}`}
            </Title>

            <Box sx={priceWrapper}>
              <Box
                width={12}
                height={12}
              >
                {Wallet}
              </Box>

              <Text
                type='success'
                styles={price}
              >
                {formatByPrecisionAndTrim(userWallet.free.toString(), currentSymbol?.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional))} {currentSymbol?.quoteAsset}
              </Text>

              <Tooltip
                title={(
                  <Box maxWidth={188}>
                    <Title
                      level={5}
                      styles={tooltipText}
                    >
                      {currentSymbol?.quoteAsset} amount currently <br /> available on Exchange account
                    </Title>
                  </Box>
                )}
              >
                <Box
                  width={12}
                  height={12}
                >
                  {InfoIcon}
                </Box>
              </Tooltip>
            </Box>
          </Box>

          <Fields
            isSkipBaseOrder={isSkipBaseOrder}
            onOrderPriceChange={handleOrderPriceChange}
            onTotalChange={handleTotalChange}
            onUnitsChange={handleUnitsChange}
            onSliderChange={handleSliderChange}
            onTriggerPriceChange={handleTriggerPriceChange}
            onSkipBaseSliderChange={handleSkipBaseSliderChange}
            onBoughtPriceChange={handleBoughtPriceChange}
            onSkipBaseUnitsChange={handleSkipBaseUnitsChange}
            onSkipBaseTotalChange={handleSkipBaseTotalChange}
          />

          <AdditionalEntry
            onAdditionalOrderPriceChange={handleAdditionalOrderPriceChange}
            onAdditionalUnitsChange={handleAdditionalUnitsChange}
            onAdditionalTotalChange={handleAdditionalTotalChange}
            onAdditionalSliderChange={handleAdditionalSliderChange}
            onAdditionalTriggerPriceChange={handleAdditionalTriggerPriceChange}
            setIsSkipAdditionalEntry={setIsSkipAdditionalEntry}
            onAdditionalOrderPricePercentChange={handleAdditionalOrderPricePercentChange}
            onAddEntry={handleAddEntry}
            onRemoveEntry={handleRemoveEntry}
          />

          <TakeProfit
            setIsSkipTakeProfit={setIsSkipTakeProfit}
            onTakeProfitOrderPriceChange={handleTakeProfitOrderPriceChange}
            onTakeProfitTriggerPriceChange={handleTakeProfitTriggerPriceChange}
            onTakeProfitOrderPricePercentChange={handleTakeProfitOrderPricePercentChange}
            onAddEntry={handleAddTakeProfitEntry}
            onRemoveEntry={handleRemoveTakeProfitEntry}
          />

          <StopLoss
            setIsSkipStopLoss={setIsSkipStopLoss}
            onStopLossTriggerPriceChange={handleStopLossTriggerPriceChange}
            onStopLossOrderPriceChange={handleStopLossOrderPriceChange}
            onStopLossOrderPricePercentChange={handleStopLossOrderPricePercentChange}
          />
        
          <Button 
            htmlType='submit'
            type='primary'
          >
            Create trade
          </Button>
        </Box>
      </FormProvider>
    
      <ConfirmTransaction
        isOpened={isModalVisible}
        closeHandler={() => setIsModalVisible(false)}
        //@ts-ignore
        data={confirmationData}
      />
    
    </>
  );
};
