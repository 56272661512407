import { Box } from '@mui/material';
import { Modal, Segmented } from 'antd';
import { useState } from 'react';
import { Card } from 'widgets';
import { getTitle } from 'shared/helpers';
import { Text } from 'shared/ui';
import { descriptions } from '../consts';
import { filterPlans, getButton, getItems, getPrice } from '../helpers';
import { IPlans } from '../interfaces';
import { list, modal, modalChildren, title, titleWrapper, wrapper } from '../styles';

export const Plans = (props: IPlans) => {
  const {
    isOpen,
    closeHandler,
    items,
    subscription,
    choosePlan,
  } = props;

  const [period, setPeriod] = useState<string>('monthly');

  const changePeriod = (period: string) => {
    setPeriod(period);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Text
            type='success'
            styles={title}
          >
            Choose your plan
          </Text>

          <Segmented
            value={period}
            onChange={changePeriod}
            options={[{
              label: 'Monthly',
              value: 'monthly',
            }, {
              label: 'Annually -50%',
              value: 'annually',
            }]}
            size='large'
          />
        </Box>

        <Box sx={list}>
          {items.filter(filterPlans(period)).sort((a, b) => a.originalFee - b.originalFee).map((item: IPlans['items'][0], index: number) => (
            <Card
              key={item.planUuid}
              title={getTitle(item.planName)}
              description={descriptions[index]}
              price={getPrice(item.originalFee, period)}
              period='monthly'
              isBset={item.planName.includes('pro')}
              items={getItems(item)}
              button={getButton(subscription, item, choosePlan)}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
