import { Box } from '@mui/material';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Text } from 'shared/ui';
import { IRangePicker } from '../interfaces';
import { labelText, wrapper } from '../styles';

export const RangePicker = (props: IRangePicker) => {
  const {
    label,
    value,
    handleSetNewDate,
    size,
    maxWidth,
    style,
  } = props;
  
  const handleDateChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates && dates[1]) {
      const adjustedDates: [dayjs.Dayjs | null, dayjs.Dayjs | null] = [dates[0], dates[1].endOf('day')];
      handleSetNewDate && handleSetNewDate(adjustedDates);
    } else {
      handleSetNewDate && handleSetNewDate(dates);
    }
  };
  
  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current > dayjs().endOf('day');
  };
  
  return (
    <Box sx={wrapper(maxWidth)}>
      {label && (
        <Text
          type='secondary'
          styles={labelText}
        >
          {label}
        </Text>
      )}

      <DatePicker.RangePicker
        value={value}
        picker='date'
        size={size || 'middle'}
        separator='-'
        panelRender={(originPanel) => {
          return (
            <Box sx={wrapper()}>
              {originPanel}
            </Box>
          );
        }}
        disabledDate={disabledDate}
        format={{
          format: 'DD-MM-YYYY',
          type: 'mask',
        }}
        onChange={handleDateChange}
        style={style}
      />
    </Box>
  );
};
