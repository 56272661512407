//@ts-nocheck

export function collectFormData(formValues: any, isSkipBaseOrder: boolean, exchangeUuid: string) {
  const {
    currentSymbol, addEntryEnabled,
    takeProfitEnabled, stopLossEnabled,
  } = formValues;
  
  const result: any = {
    baseSymbol: currentSymbol.baseAsset,
    quoteSymbol: currentSymbol.quoteAsset,
    exchangeAccountUuid: exchangeUuid,
  };

  result.baseOrder = isSkipBaseOrder
    ? collectSkipBaseOrder(formValues)
    : collectBaseOrder(formValues);

  if (addEntryEnabled) {
    result.additionalBaseOrders = collectAdditionalBaseOrders(formValues);
  }

  if (takeProfitEnabled) {
    result.takeProfits = collectTakeProfits(formValues);
  }

  if (stopLossEnabled) {
    result.stopLoss = collectStopLoss(formValues);
  }
  
  return result;
}

function collectBaseOrder(formValues: any) {
  const {
    orderType, conditionalOrderType, orderPrice, triggerPrice, triggerPriceType, units, 
  } = formValues;

  const baseOrder: any = {
    side: 'BUY',
    type: orderType,
  };

  switch (orderType) {
  case 'limit':
    baseOrder.limitPrice = orderPrice;
    baseOrder.unitsBase = units;
    break;
  case 'market':
    baseOrder.unitsBase = units;
    break;
  case 'conditional':
    if (conditionalOrderType === 'limit') {
      baseOrder.type = 'CONDITIONAL_LIMIT';
      baseOrder.limitPrice = orderPrice;
      baseOrder.triggerPrice = triggerPrice;
      baseOrder.typeTriggerPrice = triggerPriceType;
      baseOrder.unitsBase = units;
    } else if (conditionalOrderType === 'market') {
      baseOrder.type = 'CONDITIONAL_MARKET';
      baseOrder.triggerPrice = triggerPrice;
      baseOrder.typeTriggerPrice = triggerPriceType;
      baseOrder.unitsBase = units;
    }
    break;
  }

  return baseOrder;
}

function collectSkipBaseOrder(formValues: any) {
  const {
    boughtPrice, units, 
  } = formValues;

  return {
    side: 'BUY',
    type: 'SKIP_FIRST_STEP',
    boughtPrice,
    unitsBase: units,
  };
}

function collectAdditionalBaseOrders(formValues: any) {
  const {
    additionalEntries, additionalOrderType, additionalOrderPrice, additionalTriggerPrice, additionalTriggerPriceType, additionalUnits, 
  } = formValues;

  if (additionalEntries.length === 0) {
    return [{
      side: 'BUY',
      type: additionalOrderType,
      ...collectAdditionalOrderDetails(additionalOrderType, additionalOrderPrice, additionalTriggerPrice, additionalTriggerPriceType, additionalUnits),
    }];
  } else {
    return additionalEntries.map(entry => ({
      side: 'BUY',
      type: entry.orderType,
      ...collectAdditionalOrderDetails(entry.orderType, entry.price, entry.triggerPrice, entry.typeTriggerPrice, entry.unitsBase),
    }));
  }
}

function collectAdditionalOrderDetails(orderType: string, orderPrice: number, triggerPrice: number, triggerPriceType: string, units: number) {
  switch (orderType) {
  case 'limit':
    return {
      limitPrice: orderPrice,
      unitsBase: units,
    };
  case 'cond.limit':
    return {
      limitPrice: orderPrice,
      triggerPrice,
      typeTriggerPrice: triggerPriceType,
      unitsBase: units,
    };
  case 'cond.market':
    return {
      triggerPrice,
      typeTriggerPrice: triggerPriceType,
      unitsBase: units,
    };
  default:
    return {};
  }
}

function collectTakeProfits(formValues: any) {
  const {
    takeProfitEntries, takeProfitOrderType, takeProfitOrderPrice, takeProfitTriggerPrice,
    takeProfitTriggerPriceType, takeProfitSlider,
  } = formValues;

  if (takeProfitEntries.length === 0) {
    return [{
      side: 'BUY',
      type: takeProfitOrderType,
      ...collectTakeProfitDetails(takeProfitOrderType, takeProfitOrderPrice, takeProfitTriggerPrice, takeProfitTriggerPriceType, takeProfitSlider),
    }];
  } else {
    return takeProfitEntries.map(entry => ({
      side: 'BUY',
      type: entry.takeProfitOrderType,
      ...collectTakeProfitDetails(entry.takeProfitOrderType, entry.price, entry.triggerPrice, entry.typeTriggerPrice, entry.volume),
    }));
  }
}

function collectTakeProfitDetails(orderType: string, orderPrice: number, triggerPrice: number, triggerPriceType: string, percent: number) {
  const baseDetails = {
    priceRecalculation: 'AVERAGE',
    percent,
  };

  switch (orderType) {
  case 'limit':
    return {
      ...baseDetails,
      limitPrice: orderPrice,
    };
  case 'cond.market':
    return {
      ...baseDetails,
      triggerPrice,
      typeTriggerPrice: triggerPriceType,
    };
  default:
    return baseDetails;
  }
}

function collectStopLoss(formValues: any) {
  const {
    stopLossOrderType, stopLossTriggerPrice, stopLossTriggerPriceType, stopLossOrderPrice,
  } = formValues;

  const stopLoss: any = {
    side: 'BUY',
    type: stopLossOrderType,
    triggerPrice: stopLossTriggerPrice,
    typeTriggerPrice: stopLossTriggerPriceType,
    priceRecalculation: 'AVERAGE',
  };

  if (stopLossOrderType === 'cond.limit') {
    stopLoss.limitPrice = stopLossOrderPrice;
  }

  return stopLoss;
}
