import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { sliderWrapper } from 'pages/manual-trading/trading-terminal/components/fields/styles';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { PlusGreen } from 'shared/icons';
import {
  Input,
  LabelInput,
  MainButton,
  SingleSelect,
  Slider,
  Switch,
  Text,
  Title,
} from 'shared/ui';
import { SafetyOrders } from '../components';
import { segments } from '../consts';
import { IAdditionalEntry } from '../interfaces';
import { action, actions, actionText, button, buttonTitle, suffixText, wrpaper } from '../styles';

export const AdditionalEntry = (props: IAdditionalEntry) => {
  const {
    onAdditionalOrderPriceChange,
    onAdditionalUnitsChange,
    onAdditionalTotalChange,
    onAdditionalSliderChange,
    onAdditionalTriggerPriceChange,
    setIsSkipAdditionalEntry,
    onAdditionalOrderPricePercentChange,
    onAddEntry,
    onRemoveEntry,
  } = props;
  
  const {
    control,
    formState: {
      errors, 
    },
    trigger,
    setValue,
    watch,
  } = useFormContext();
    
  const additionalOrderType = watch('additionalOrderType');
  const isAddEntryEnabled = watch('addEntryEnabled');
  const currentSymbol = watch('currentSymbol');
  const baseAsset = watch('baseAsset');
  const quoteAsset = watch('quoteAsset');
  const additionalTriggerPricePercent = watch('additionalTriggerPricePercent');  
  const additionalEntries = watch('additionalEntries');
  
  const changeSegmentHandler = (value: string) => {
    setValue('additionalOrderType', value);
  };

  const renderOrderPriceField = () => {
    if (additionalOrderType === 'cond.market') {
      return null;
    }

    return (
      <Box
        sx={action}
        key={'Order price'}
      >
        <Text
          type='secondary'
          styles={actionText}
        >
          {'Order price'}
        </Text>
  
        <Box sx={actions}>
          <Controller
            name='additionalOrderPrice'
            control={control}
            rules={{
              required: 'This field is required', 
              validate: (value) => {
                const numValue = Number(value);
                if (isNaN(numValue)) return 'Value should be a number';
                if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                return true;
              },
            }}
            render={({
              field, 
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                    field.onChange(value);
                    onAdditionalOrderPriceChange(value);
                  }
                  trigger('additionalOrderPrice');
                }}
                icon={quoteAsset}
                status={errors.additionalOrderPrice ? 'error' : undefined}
                onBlur={() => {
                  const formattedValue = formatByPrecisionAndTrim(
                    field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                  field.onChange(formattedValue);
                  onAdditionalOrderPriceChange(formattedValue);
                  trigger('additionalOrderPrice');
                }}
              />
            )}
          />
  
          <Controller
            name='additionalOrderPricePercent'
            control={control}
            render={({
              field, 
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  onAdditionalOrderPricePercentChange(value);
                }}
                maxWidth={58}
                icon='%'
              />
            )}
          />
        </Box>
        {errors.additionalOrderPrice && (
          <Text type='danger' styles={{
            fontSize: '12px', marginTop: '4px', 
          }}>
            {errors.additionalOrderPrice.message as string}
          </Text>
        )}
      </Box>
    );
  };
  
  const renderUnitsField = () => {
    return (
      <>
        <Controller
          name='additionalUnits'
          control={control}
          rules={{
            required: 'This field is required',
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) return 'Value should be a number';
              if (numValue < Number(currentSymbol.lotMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.lotMin)}`;
              if (numValue > Number(currentSymbol.lotMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.lotMax)}`;
              return true;
            },
          }}
          render={({
            field,
          }) => (
            <LabelInput
              label='Units'
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalUnitsChange(value);
                }
                trigger('additionalUnits');
              }}
              icon={baseAsset}
              onBlur={() => {
                const formattedValue = formatByPrecisionAndTrim(
                  field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
                field.onChange(formattedValue);
                onAdditionalUnitsChange(formattedValue);
                trigger('additionalUnits');
              }}
              status={errors.additionalUnits ? 'error' : undefined}
            />
          )}
        />
        {errors.additionalUnits && (
          <Text type='danger' styles={{
            fontSize: '12px', marginTop: '4px', 
          }}>
            {errors.additionalUnits.message as string}
          </Text>
        )}
      </>
    );
  };
  
  const renderTotalField = () => (
    <>
      <Controller
        name='additionalTotal'
        control={control}
        rules={{
          required: 'This field is required', 
          validate: (value) => {
            const numValue = Number(value);
            if (isNaN(numValue)) return 'Value should be a number';
            if (numValue < Number(currentSymbol.minNotional)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.minNotional)}`;
            if (numValue > Number(currentSymbol.maxNotional)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.maxNotional)}`;
            return true;
          },
        }}
        render={({
          field, 
        }) => (
          <LabelInput
            label='Total'
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onAdditionalTotalChange(value);
              }
              trigger('additionalTotal');
            }}
            icon={quoteAsset}
            status={errors.additionalTotal ? 'error' : undefined}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value,
                currentSymbol.quoteAssetPrecision,
                Number(currentSymbol.minNotional),
                Number(currentSymbol.maxNotional),
              );
              field.onChange(formattedValue);
              onAdditionalTotalChange(formattedValue);
              trigger('additionalTotal');
            }}
          />
        )}
      />
      {errors.additionalTotal && (
        <Text type='danger' styles={{
          fontSize: '12px', marginTop: '4px', 
        }}>
          {errors.additionalTotal?.message as string}
        </Text>
      )}
    </>
  );
  
  const renderSlider = () => (
    <Box sx={sliderWrapper}>
      <Controller
        name='additionalSlider'
        control={control}
        render={({
          field, 
        }) => (
          <Slider
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              onAdditionalSliderChange(value);
              trigger('additionalSlider');
            }}
          />
        )}
      />
      <Controller
        name='additionalSlider'
        control={control}
        render={({
          field, 
        }) => (
          <Input
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              onAdditionalSliderChange(parseFloat(value));
              trigger('additionalSlider');
            }}
            placeholder='100%'
            maxWidth={58}
            icon='%'
          />
        )}
      />
    </Box>
  );
  
  const renderTriggerPriceField = () => {
    if (additionalOrderType !== 'cond.market' && additionalOrderType !== 'cond.limit') {
      return null;
    }

    return (
      <Box
        key={'Trigger price'}
        sx={action}
      >
        <Text
          type='secondary'
          styles={actionText}
        >
          Trigger price
        </Text>

        <Box sx={actions}>
          <Controller
            name='additionalTriggerPrice'
            control={control}
            rules={{
              required: 'Это поле обязательно',
              validate: (value) => {
                const numValue = Number(value);
                if (isNaN(numValue)) return 'Value should be a number';
                if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                return true;
              },
            }}
            render={({
              field,
            }) => (
              <Input
                value={field.value}
                onChange={(value) => {
                  if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                    field.onChange(value);
                    onAdditionalTriggerPriceChange(value);
                  }
                  trigger('additionalTriggerPrice');
                }}
                onBlur={() => {
                  const formattedValue = formatByPrecisionAndTrim(
                    field.value,
                    currentSymbol.quoteAssetPrecision,
                    Number(currentSymbol.priceMin),
                    Number(currentSymbol.priceMax),
                  );
                  field.onChange(formattedValue);
                  onAdditionalTriggerPriceChange(formattedValue);
                  trigger('additionalTriggerPrice');
                }}
                icon={quoteAsset}
                addonBefore={`${additionalTriggerPricePercent}%`}
                status={errors.additionalTriggerPrice ? 'error' : undefined}
              />
            )}
          />

          <Controller
            name='additionalTriggerPriceType'
            control={control}
            render={({
              field, 
            }) => (
              <SingleSelect
                maxWidth='max-content'
                select={{
                  value: field.value,
                  placeholder: 'Last',
                  onChange: (value) => {
                    field.onChange(value);
                    trigger('additionalTriggerPriceType');
                  },
                }}
                options={[
                  {
                    label: 'Last', value: 'last', 
                  },
                  {
                    label: 'Bid', value: 'bid', 
                  },
                  {
                    label: 'Ask', value: 'ask', 
                  },
                ]}
              />
            )}
          />
        </Box>
        {errors.additionalTriggerPrice && (
          <Text type='danger' styles={{
            fontSize: '12px', marginTop: '4px', 
          }}>
            {errors.additionalTriggerPrice.message as string}
          </Text>
        )}
      </Box>
    );
  };
  
  return (
    <Box sx={wrpaper}>
      <Switch
        value={isAddEntryEnabled}
        onChange={setIsSkipAdditionalEntry}
        suffixText={(
          <Title
            level={5}
            styles={suffixText}
          >
            Additional entry
          </Title>
        )}
        size='small'
      />

      {isAddEntryEnabled && (
        <>
          <Segmented
            value={additionalOrderType}
            onChange={changeSegmentHandler}
            options={segments}
            block={true}
          />
  
          {additionalEntries.length > 0 && (
            <SafetyOrders
              additionalEntries={additionalEntries}
              onRemoveEntry={onRemoveEntry}
            />
          )}

          {renderTriggerPriceField()}
          {renderOrderPriceField()}
          {renderUnitsField()}
          {renderTotalField()}
          {renderSlider()}

          <MainButton
            icon={PlusGreen}
            styles={button}
            onClick={onAddEntry}
          >
            <Title
              level={5}
              styles={buttonTitle}
            >
              Add entry
            </Title>
          </MainButton>
        </>
      )}
    </Box>
  );
};
