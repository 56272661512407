import { Box } from '@mui/material';
import { Button, Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShortExchangesData } from '../../entities/exchange/helpers/set-short-exchanges-data.ts';
import { getShortExchangesData } from '../../entities/exchange/model/selectors/get-short-exchanges-data/get-short-exchanges-data.ts';
import { exchangeActions } from '../../entities/exchange/model/slices/exchange-slice.ts';
import { getAllActiveTrades } from '../../entities/terminal/model/selectors/get-all-active-trades/get-all-active-trades.ts';
import { getAllExchangeSymbols } from '../../entities/terminal/model/selectors/get-all-exchange-symbols/get-all-exchange-symbols.ts';
import { getChosenExchange } from '../../entities/terminal/model/selectors/get-chosen-exchange/get-chosen-exchange.ts';
import { getDailyChange } from '../../entities/terminal/model/selectors/get-daily-change/get-daily-change.ts';
import { terminalActions } from '../../entities/terminal/model/slices/terminal-slice.ts';
import { userActions } from '../../entities/user/model/slices/user-slice.ts';
import { currencyFormatter } from '../../shared/helpers/currency-formatter.ts';
import { getCapitalizedExchangeTitle } from '../../shared/helpers/get-capitalized-exchange-title.ts';
import { getAuthToken, getSkyrexUuid, getUserEmail } from '../../shared/helpers/storage-helper.ts';
import { Loader } from '../../shared/ui/loader/ui/loader.tsx';
import { SingleSelect } from '../../shared/ui/selects/single-select/ui/single-select.tsx';
import { Table } from '../../shared/ui/table/ui/table.tsx';
import { Text } from '../../shared/ui/text/ui/text.tsx';
import { getExchanges } from '../../widgets/exchanges/api/get-exchanges.ts';
import { PieChart } from '../../widgets/pie-chart/ui/pie-chart.tsx';
import { columns } from '../manual-trading/trading-terminal/consts/columns.tsx';
import { filters } from '../manual-trading/trading-terminal/styles/filters.ts';
import { tableWrapper } from '../manual-trading/trading-terminal/styles/table-wrapper.ts';
import { ExchangeForm } from './order-panel/ExchangeForm/ExchangeForm.jsx';
import {
  connectAccountButtonStyle,
  successModalBodyContainer,
  successModalButtonContainer,
  successModalTextStyle,
  windowSizeModalTextStyle,
} from './order-panel/ExchangeForm/exchange-form.styles.ts';
import { getTerminalSnapshotsData } from './order-panel/ExchangeForm/helpers/get-snapshots-terminal-page.ts';
import { ActiveTrades } from './order-panel/ExchangeTable/activeTrades.tsx';
import { TVChartContainer } from './trading-chart/TVChartContainer/index.jsx';
import { getExchangeSymbols } from './trading-chart/helpers/get-symbols.ts';

export const getUniqueSymbolCodes = (allExchangeSymbols) => {

  const result = allExchangeSymbols.reduce((uniqueSymbols, symbol) => {
    if (!uniqueSymbols.some(s => s.quoteAsset === symbol.quoteAsset)) {
      uniqueSymbols.push(symbol);
    }
    return uniqueSymbols;
  }, []);

  return result;
};

export const calculateMarketOptions = (userWallet, uniqueSymbolCodes) => {
  // Создаем массив объектов, содержащих информацию о каждом токене и его балансе
  const options = uniqueSymbolCodes.map(token => {
    const walletBalance = userWallet?.find(el => el.symbol === token.quoteAsset)?.free || '0';
    const formattedBalance = currencyFormatter(walletBalance, token.chartPrecision);

    return {
      label: token.quoteAsset,
      value: `${formattedBalance}-${token.quoteAsset}`,
      balance: parseFloat(walletBalance),
      formattedValue: formattedBalance,
    };
  });

  // Сортируем массив по убыванию баланса
  const sorted = options.sort((a, b) => b.balance - a.balance);

  return sorted;
};

export const formatTradingPair = (baseAsset, quoteAsset) => {
  return `${baseAsset}${quoteAsset}`;
};
// Функция для фильтрации торговых пар
export const getFilteredTradingPairs = (exchangeSymbols, defaultMarket, userWallet) => {

  const filteredExchangeSymbols = exchangeSymbols.filter((el) => el.quoteAsset === defaultMarket?.label);
  
  //добавить округления здесь
  
  const mappedSymbols = filteredExchangeSymbols.map((el, index) => {
    const balance = userWallet?.find((elem) => elem.symbol === el.baseAsset)?.free ?? '0';
    
    const formattedValue = currencyFormatter(balance, el.baseCurrencyChartPrecision);
    
    return {
      title: `${el.baseAsset} - ${el.quoteAsset}`,
      value: `${formattedValue}-${index}`,
      label: `${el.baseAsset} - ${el.quoteAsset}`,
      balance: balance,
      formattedValue: formattedValue,
      formattedPair: formatTradingPair(el.baseAsset, el.quoteAsset),
    };
  });

  // Sort the mapped symbols by balance in descending order
  const sortedSymbols = mappedSymbols.sort((a, b) => b.balance - a.balance);

  return sortedSymbols;
};

const Terminal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allConnectedExchanges = useSelector(getShortExchangesData);
  const chosenExchange = useSelector(getChosenExchange);
  const activeTrades = useSelector(getAllActiveTrades);
  const allExchangeSymbols = useSelector(getAllExchangeSymbols);
  const dailyChange = useSelector(getDailyChange);

  const [isLoading, setIsLoading] = useState(true);
  const [mobileModal, setMobileModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [exchangeAccountUuid, setExchangeAccountUuid] = useState(null);
  const [exchangeCode, setExchangeCode] = useState(null);
  const [defaultExchange, setDefaultExchange] = useState(null);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [defaultMarket, setDefaultMarket] = useState(null);
  const [defaultTraidingPair, setDefaultTraidingPair] = useState(null);
  const [isSectionLoading, setIsSectionLoading] = useState(false);

  const [fetchTradesTrigger, setFetchTradesTrigger] = useState(false);

  const [transformedExchanges, setTransformedExchanges] = useState([]);

  const exchangeFormDisabled = !chosenExchange?.balances?.length;

  const userWallet = chosenExchange?.balances;

  const uniqueSymbolCodes = useMemo(() => {
    const result = getUniqueSymbolCodes(allExchangeSymbols);
    return result;
  }, [allExchangeSymbols]);

  const marketOptions = useMemo(() => {
    return calculateMarketOptions(userWallet, uniqueSymbolCodes);
  }, [userWallet, uniqueSymbolCodes]);

  const filteredTradingPairs = useMemo(() => {
    return getFilteredTradingPairs(allExchangeSymbols, defaultMarket, userWallet);
  }, [uniqueSymbolCodes, defaultMarket, userWallet]);

  useEffect(() => {
    if (chosenExchange) {
      const marketOptions = uniqueSymbolCodes.map((token, index) => {
        const walletBalance = userWallet?.find((el) => el.symbol === token.quoteAsset)?.free || '0';
        const formattedValue = currencyFormatter(walletBalance, token.chartPrecision);
        
        return {
          label: token.quoteAsset,
          value: formattedValue,
        };
      });

      setDefaultMarket(marketOptions.find((el) => el.label === 'USDT'));
      setDefaultExchange(chosenExchange);
    }
  }, [chosenExchange, userWallet, uniqueSymbolCodes]);

  useEffect(() => {
    if (filteredTradingPairs.length !== 0) {
      const defaultPair = filteredTradingPairs.find((el) => el.formattedPair === 'BTCUSDT') ?? filteredTradingPairs[0];

      setDefaultTraidingPair(defaultPair);
    }
  }, [filteredTradingPairs]);

  useEffect(() => {
    const mappedExchanges = allConnectedExchanges.map(exchange => ({
      label: exchange.accountName,
      value: exchange.exchangeAccountUuid,
      exchangeCode: exchange.exchangeCode,
    }));

    setTransformedExchanges(mappedExchanges);
  }, [allConnectedExchanges]);

  useEffect(() => {
    if (defaultExchange && defaultMarket && defaultTraidingPair) {
      setIsLoading(false);
    }
  }, [defaultExchange, defaultMarket, defaultTraidingPair]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setMobileModal(windowWidth < 1050);
  }, [windowWidth]);

  const handleNavigateExchange = () => {
    navigate('/home');
  };

  const handleNavigateDashboard = () => {
    navigate('/my-accounts');
  };

  const closeModal = () => {
    handleNavigateExchange();
    setIsModalOpen(false);
  };

  const closeMobileModal = () => {
    handleNavigateDashboard();
    setMobileModal(false);
  };

  const handleFormSubmitSuccess = () => {
    setFetchTradesTrigger((prev) => !prev);
    // setFetchTradesTrigger(true);
    // return resetFetch();
  };

  const resetFetch = () => {
    setTimeout(() => {
      setFetchTradesTrigger(false);
    }, 100);
  };

  const handleSelectExchange = (exchangeAccountUuid) => {
    const selectedExchange = allConnectedExchanges.find((exchange) => exchange.exchangeAccountUuid === exchangeAccountUuid);
    setExchangeAccountUuid(selectedExchange.exchangeAccountUuid);
    setExchangeCode(selectedExchange.exchangeCode);
  };

  const handleSelectMarket = (value, label, key) => {
    setDefaultMarket(label);
  };

  const handleSelectTradingPair = (value, label) => {
    const currentSymbol = allExchangeSymbols.find((element) => element.symbol === label?.formattedPair);
    dispatch(terminalActions.setCurrentSymbol(currentSymbol));
    setDefaultTraidingPair(label);
  };

  useEffect(() => {
    const currentSymbol = allExchangeSymbols.find((element) => element.symbol === defaultTraidingPair?.formattedPair);
    dispatch(terminalActions.setCurrentSymbol(currentSymbol));
  }, [defaultTraidingPair]);

  const getExchangesData = async () => {
    try {
      setIsLoading(true);
      const authToken = getAuthToken();
      const skyrexUserUuid = getSkyrexUuid();
      const skyrexUserEmail = getUserEmail();

      if (authToken) {
        dispatch(userActions.auth(true));
        dispatch(userActions.setSkyrexUuid(skyrexUserUuid ?? ''));
        dispatch(userActions.setUserEmail(skyrexUserEmail ?? ''));
      }

      const exchangeAccounts = await setShortExchangesData(skyrexUserUuid ?? '');

      dispatch(exchangeActions.setAllExchangesShortData(exchangeAccounts));
      setExchangeCode(exchangeAccounts[0]?.exchangeCode ?? 'binance');
      setExchangeAccountUuid(exchangeAccounts[0]?.exchangeAccountUuid);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchExchangeSymbols = async (exchangeCode) => {
    try {
      const exchangeSymbols = await getExchangeSymbols(exchangeCode);
      dispatch(terminalActions.setExchangeSymbols(exchangeSymbols));
    } catch (error) {
      console.log(error, '=====error');
      dispatch(terminalActions.setExchangeSymbols([]));
    }
  };

  const getSingleAccountFinanceData = async (exchangeAccountUuid) => {
    try {
      setIsSectionLoading(true);
      const responseWithFinancialData = await getExchanges([exchangeAccountUuid]);
      const chosenExchange = responseWithFinancialData?.data?.accounts[0];
      dispatch(terminalActions.setChosenExchange(chosenExchange));
      dispatch(terminalActions.setWalletData(chosenExchange?.balances));
      setDefaultExchange(chosenExchange);
    } catch (error) {
      console.log('===error in single account finance data');
    } finally {
      setIsSectionLoading(false);
    }
  };

  const getSnapshotsArray = async (chosenExchange, exchangeAccountUuid) => {
    try {
      if (!exchangeAccountUuid || !chosenExchange) {
        dispatch(terminalActions.setDailyChange(
          {
            usdtDailyChangePercent: '0',
            usdtDailyChangeValue: 0,
          },
        ));
        setIsChartLoading(false);
        return;
      }

      const skyrexUserUuid = getSkyrexUuid();
      const getDailyChange = await getTerminalSnapshotsData(chosenExchange, skyrexUserUuid, exchangeAccountUuid);
      dispatch(terminalActions.setDailyChange(getDailyChange));
      setIsChartLoading(false);
    } catch (error) {
      console.log('===error snapshots');
    }
  };

  useEffect(() => {
    if (exchangeAccountUuid) {
      getSingleAccountFinanceData(exchangeAccountUuid);
    }
  }, [allConnectedExchanges, exchangeAccountUuid]);

  useEffect(() => {
    if (exchangeCode) {
      fetchExchangeSymbols(exchangeCode);
    }
  }, [exchangeCode]);

  useEffect(() => {
    if (chosenExchange) {
      getSnapshotsArray(chosenExchange, exchangeAccountUuid);
    }
  }, [chosenExchange, exchangeAccountUuid]);

  useEffect(() => {
    getExchangesData();
  }, []);

  if (isLoading) {
    return (
      <Loader isContentOverflow={true} />
    );
  }

  return (
    <>
      {windowWidth >= 1050 ? (
        <div style={{
          marginTop: '30px',
        }}>
          <Box
            sx={tableWrapper}
          >
            <Box sx={filters}>
              <SingleSelect
                label='Exchange account'
                options={transformedExchanges}
                select={{
                  value: defaultExchange.accountName 
                    ? {
                      value: defaultExchange.accountName,
                      label: defaultExchange.exchangeCode,
                    }
                    : null,
                  placeholder: 'Connect account',
                  onChange: handleSelectExchange,
                }}
                optionRender={(option) => {
                  return (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Text type='success'>
                        {option.label}
                      </Text>

                      <Box
                        display='flex'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Text type='secondary'>
                          {getCapitalizedExchangeTitle(option?.data?.exchangeCode?.split(' ')[0])}
                        </Text>
                      </Box>
                    </Box>);
                }}
                labelRender={(props) => {
                  return (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      gap={1}
                    >
                      <Text type='success'>{props.value?.toString()}</Text>
                      <Box
                        display='flex'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Text type='secondary'>{getCapitalizedExchangeTitle(props.label)}</Text>
                      </Box>
                    </Box>
                  );
                }}
              />

              <SingleSelect
                label='Market'
                showSearch={true}
                // передать массив доступных монет
                options={marketOptions}
                select={{
                  value: defaultMarket,
                  placeholder: 'Select market',
                  onChange: handleSelectMarket,
                }}
                optionRender={(option) => {
                  return (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Text type='success'>
                        {option.label}
                      </Text>

                      <Box
                        display='flex'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Text>
                          {option?.value?.split('-')[0]}
                        </Text>

                        <Text type='secondary'>
                          {option.label?.toString().split(' ')[0]}
                        </Text>
                      </Box>
                    </Box> 
                  );
                }}
                labelRender={(props) => {
                  return (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      gap={1}
                    >
                      <Text type='success'>{props.label}</Text>

                      <Box
                        display='flex'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Text>{props?.value?.split('-')[0]}</Text>
                        <Text type='secondary'>{props.label}</Text>
                      </Box>
                    </Box>
                  );
                }}
              />

              <SingleSelect
                showSearch={true}
                label='Trading Pair'
                options={filteredTradingPairs}
                select={{
                  value: defaultTraidingPair,
                  placeholder: 'Select trading pair',
                  onChange: handleSelectTradingPair,
                }}
                optionRender={(option) => {
                  return (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Text type='success'>
                        {option.label}
                      </Text>

                      <Box
                        display='flex'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Text>
                          {option?.value?.split('-')[0]}
                        </Text>

                        <Text type='secondary'>
                          {option.label?.toString().split(' ')[0]}
                        </Text>
                      </Box>
                    </Box>
                  );  
                }}
                labelRender={(props) => {
                  return (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      gap={1}
                    >
                      <Text type='success'>{props.label}</Text>
                      <Box
                        display='flex'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Text>{props.value?.split('-')[0]}</Text>
                        <Text type='secondary'>{props.label?.toString().split(' ')[0]}</Text>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>

            {isSectionLoading && (
              <Loader isContentOverflow={true} />
            )}
            <Table
              itemsCount={5}
              columns={columns}
              items={userWallet ?? []}
              graphic={(
                <Box
                  display='block'
                  width={209}
                >
                  <PieChart
                    loading={isChartLoading}
                    data={userWallet ? userWallet?.map((balance) => (
                      {
                        value: balance.usdt.total,
                        amountForTooltip: balance.total,
                        name: balance.symbol,
                        totalUsdt: balance.usdt.total,
                        totalBtc: balance.btc.total,
                        overallBtcValue: chosenExchange?.totalBtc,
                        overallUsdtValue: chosenExchange?.totalUsdt,
                        overallBtcShare: (+balance.btc.total / +chosenExchange?.totalBtc) * 100,
                        overallUsdtShare: (+balance.usdt.total / +chosenExchange?.totalUsdt) * 100,
                      }
                    )) : []}
                    totalBtc={chosenExchange?.totalBtc}
                    totalUsdt={chosenExchange?.totalUsdt}
                    usdtDailyChangePercent={chosenExchange?.balances?.length ? dailyChange?.usdtDailyChangePercent : '0'}
                    btcDailyChangePercent={chosenExchange?.balances?.length ? dailyChange?.usdtDailyChangePercent : '0'}
                  />
                </Box>
              )}
            />
          </Box>


          {/* <DropdownContainer /> */}
          {/* <TerminalPie /> */}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              justifyContent: 'space-between',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <TVChartContainer
                exchangeName={exchangeCode}
                tradingPair={defaultTraidingPair}
                market={defaultMarket}
              />
            </div>

            <div
              className='custom-scrollbar'
              style={{
                flex: 1,
                maxWidth: '253px',
                marginLeft: '20px',
                marginRight: '-5px',
                maxHeight: '550px',
                paddingRight: '7px',
                boxSizing: 'border-box',
              }}
            >
              <ExchangeForm
                formDisabled={exchangeFormDisabled}
                exchangeAccountUuid={exchangeAccountUuid}
                tradingPaire={defaultTraidingPair}
                onFormSubmitSuccess={handleFormSubmitSuccess}
              />
            </div>
          </div>
          <ActiveTrades
            exchangeAccountUuid={exchangeAccountUuid}
            fetchTrigger={fetchTradesTrigger}
            trades={activeTrades}
          />
        </div>
      ) : (
        <div style={{
          backgroundColor: 'white', height: '100vh',
        }} />
      )}
      <Modal
        closable={false}
        centered
        width={420}
        open={isModalOpen}
        onCancel={closeModal}
        styles={{
          mask: {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
        }}
        footer={[
          <div style={
            successModalButtonContainer
          }>
            <Button
              style={
                connectAccountButtonStyle
              }
              key='submit' type='primary' onClick={closeModal}>
              Connect exchange account
            </Button>
          </div>,
        ]}
      >
        <div
          style={
            successModalBodyContainer
          }
        >
          <div
            style={successModalTextStyle}
          >Connect exchange account to use terminal</div>
        </div>
      </Modal>
      <Modal
        centered={true}
        closable={false}
        open={mobileModal}
        footer={[
          <div style={
            successModalButtonContainer
          }>
            <Button
              style={
                connectAccountButtonStyle
              }
              key='submit' type='primary' onClick={closeMobileModal}>
              OK
            </Button>
          </div>,
        ]}
      >
        <div
          style={
            successModalBodyContainer
          }
        >
          <div
            style={windowSizeModalTextStyle}
          >Page is not supported on current screen size</div>
        </div>
      </Modal>
    </>
  );
};

export default Terminal;
