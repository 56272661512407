import { SxProps, Theme } from '@mui/material';

export const image: SxProps<Theme> = {
  maxWidth: 160,
  '&>img': {
    width: '100%',
    height: '100%',
    display: 'block',
  },
};
